import React from "react";
import clsx from "clsx";
import { Container, Text, Image, ButtonAlt } from "@atoms";

const ImageCopyGrid = ({
  bgColor,
  copy,
  items,
  paddingBottom,
  paddingTop,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        "relative pb-16 pt-16",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white",
          "md:pt-32": paddingTop,
          "md:pb-32": paddingBottom,
        },
        _className
      )}
    >
      <Container className="relative z-20">
        <div className="flex flex-col items-center">
          {copy && (
            <div className="mx-auto max-w-3xl">
              <Text
                richText
                className={clsx(
                  "bold-carnation text-center prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark": bgColor === "midnight",
                  }
                )}
              >
                {copy}
              </Text>
            </div>
          )}
          {items && (
            <div className="mt-12 flex w-full flex-wrap justify-center gap-8">
              {items.map(item => {
                return (
                  <div
                    key={item.uid}
                    className="flex flex-auto flex-col items-center space-y-4 text-center sm:flex-[0_1_calc(50%-1rem)] md:flex-[0_1_calc(33.33%-1.34rem)]"
                  >
                    <div className="relative aspect-square w-1/2 sm:w-3/4">
                      <Image image={item.image} fill className="rounded-full" />
                    </div>
                    {item.heading && (
                      <h3 className="mt-2 font-heading text-2xl font-bold">
                        {item.heading}
                      </h3>
                    )}
                    <Text
                      className={clsx(
                        "bold-carnation mt-2 text-sm leading-normal",
                        {
                          "prose-dark": bgColor === "midnight",
                          prose: bgColor !== "midnight",
                        }
                      )}
                    >
                      {item.copy}
                    </Text>
                    {(item.primaryLink?.url || item.secondaryLink?.url) && (
                      <div className="flex flex-col space-y-2">
                        {item.primaryLink?.url && (
                          <ButtonAlt to={item.primaryLink.url}>
                            {item.primaryLink.text || "Read More"}
                          </ButtonAlt>
                        )}
                        {item.secondaryLink?.url && (
                          <ButtonAlt to={item.secondaryLink.url}>
                            {item.secondaryLink.text || "Read More"}
                          </ButtonAlt>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

ImageCopyGrid.defaultProps = {
  overlap: false,
};

export default ImageCopyGrid;
